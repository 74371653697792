import { TextField as MuiTextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';

import { MuiTextFieldProps, RHFTextFieldProps } from './type';

export const DebounceInput = <T extends Record<string, unknown> = Record<string, string>>({
  name,
  control,
  FormHelperTextProps,
  rules,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  const [innerValue, setInnerValue] = useState('');

  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    rules: rules,
  });

  const { onChange, ...restField } = field;

  const defaultProps: MuiTextFieldProps = {
    InputLabelProps: { disableAnimation: true, shrink: true },
    InputProps: { disableUnderline: true },
    'data-test': rest['data-test'],
    variant: 'standard',
  };

  useEffect(() => {
    if (field.value && typeof field.value === 'string') setInnerValue(field.value);
  }, [field.value, field.name]);

  // Debounced Callback Deferring the form update
  const debouncedHandleChange = useMemo(() => debounce(onChange, 300), [onChange]);

  // The actual HandleChange
  const handleChange = useCallback(
    event => {
      event.persist();
      setInnerValue(event.target.value);
      debouncedHandleChange(event);
    },
    [debouncedHandleChange]
  );

  return (
    <MuiTextField
      {...defaultProps}
      {...rest}
      {...restField}
      onChange={handleChange}
      value={innerValue}
      FormHelperTextProps={FormHelperTextProps}
      disabled={isSubmitting || rest.disabled}
      error={invalid || rest.error}
      helperText={error?.message ?? rest.helperText}
    />
  );
};
