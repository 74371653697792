import { TextField as MuiTextField } from '@mui/material';
import { useController } from 'react-hook-form';

import { DebounceInput } from './DebounceRHFTextField';
import { MuiTextFieldProps, RHFTextFieldProps } from './type';

export const RHFTextField = <T extends Record<string, unknown> = Record<string, string>>({
  useDebounce,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  if (useDebounce) return <DebounceInput {...rest} />;
  return <NormalTextField {...rest} />;
};

const NormalTextField = <T extends Record<string, unknown> = Record<string, string>>({
  name,
  control,
  FormHelperTextProps,
  rules,
  ...rest
}: RHFTextFieldProps<T>): JSX.Element => {
  const {
    field,
    fieldState: { invalid, error },
    formState: { isSubmitting },
  } = useController({
    control,
    name,
    rules: rules,
  });

  const defaultProps: MuiTextFieldProps = {
    InputLabelProps: { disableAnimation: true, shrink: true },
    InputProps: { disableUnderline: true },
    'data-test': rest['data-test'],
    variant: 'standard',
  };

  return (
    <MuiTextField
      {...defaultProps}
      {...field}
      {...rest}
      FormHelperTextProps={FormHelperTextProps}
      disabled={isSubmitting || rest.disabled}
      error={invalid || rest.error}
      helperText={error?.message ?? rest.helperText}
    />
  );
};
