import { darken } from '@mui/material/styles';

/* eslint-disable sort-keys-fix/sort-keys-fix */
export const COLORS = {
  // Primary
  AQUA: '#1FBAB0',
  REGAL: '#0076BD',
  REGAL008: '#EBF4FA',
  REGAL016: '#D6E9F4',
  WHITE: '#FFF',

  // Texts
  TEXT_DARK: '#0D0D0D', // Black 095
  TEXT_LIGHT: '#666666', // Black 060

  // Status / Alerts
  TOMATO: '#FF7043',

  HONEY: '#E8B046',
  MENTHOL: '#2CBA8F',
  RASPBERRY: '#D83158',

  HONEY008: '#FDF9F0',
  MENTHOL008: '#EEF9F6',
  RASPBERRY008: '#FCEEF2',

  HONEY016: '#C5963D',
  MENTHOL016: '#279E7A',
  RASPBERRY016: '#B82B4C',

  // Highlights
  CORN: '#FEDF71',

  // Grey Variants
  BLACK002: '#FAFAFA',
  BLACK004: '#F5F5F5',
  BLACK006: '#F0F0F0',
  BLACK008: '#EBEBEB',
  BLACK010: '#E6E6E6', // BORDER
  BLACK016: '#D6D6D6',
  BLACK030: '#B3B3B3', // INACTIVE

  // COMMON
  BACKGROUND: '#EFEFEF',
  BACKGROUND_ERROR: '#FFFBFC',
  DL_BACKGROUND_VIOLET: '#98257E',
  DL_BACKGROUND_BLUE: '#5AC6DD',
} as const;
/* eslint-enable sort-keys-fix/sort-keys-fix */

export const palette = {
  action: {
    disabled: COLORS.BLACK030,
    inactive: COLORS.BLACK030,
  },
  background: {
    boxDark: COLORS.BLACK004,
    boxLight: COLORS.BLACK002,
    default: COLORS.BACKGROUND,
    dl_blue: COLORS.DL_BACKGROUND_BLUE,
    dl_violet: COLORS.DL_BACKGROUND_VIOLET,
    error: COLORS.BACKGROUND_ERROR,
    grayLight: COLORS.BLACK010,
    paper: COLORS.WHITE,
    sky: COLORS.REGAL008,
    tomato: COLORS.TOMATO,
  },
  corn: COLORS.CORN,
  default: {
    contrastText: COLORS.WHITE,
    dark: darken(COLORS.TEXT_LIGHT, 0.16),
    main: COLORS.TEXT_LIGHT,
  },
  divider: COLORS.BLACK010,
  error: {
    contrastText: COLORS.WHITE,
    dark: COLORS.RASPBERRY016,
    light: COLORS.RASPBERRY008,
    main: COLORS.RASPBERRY,
  },
  info: {
    contrastText: COLORS.WHITE,
    light: COLORS.REGAL008,
    main: COLORS.REGAL,
  },
  primary: {
    contrastText: COLORS.WHITE,
    main: COLORS.REGAL,
  },
  secondary: {
    contrastText: COLORS.WHITE,
    main: COLORS.AQUA,
  },
  success: {
    contrastText: COLORS.WHITE,
    dark: COLORS.MENTHOL016,
    light: COLORS.MENTHOL008,
    main: COLORS.MENTHOL,
  },
  text: {
    disabled: COLORS.BLACK030,
    hint: COLORS.TEXT_LIGHT,
    primary: COLORS.TEXT_DARK,
    secondary: COLORS.TEXT_LIGHT,
  },
  warning: {
    contrastText: COLORS.WHITE,
    light: COLORS.HONEY008,
    main: COLORS.HONEY,
  },
};
